import React from "react";
import { Link } from "gatsby";

import { Layout, Section, Text, Column } from "../components";

export default function PageNotFound() {
  return (
    <Layout>
      <Section headline="Page not found">
        <Column>
          <Text>
            Wir haben die Seite leider nicht gefunden. Bitte gehen Sie zurück
            nach <Link to="/">Home</Link>.
          </Text>
        </Column>
      </Section>
    </Layout>
  );
}
